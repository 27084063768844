/*
* 
*
* 契約の流れ
* 
*/

.flow {
  background-color: #00b695;
  color: #fff;
  padding-bottom: 90px;

  @media screen and (min-width: 0px) and (max-width: 767px) {

  }
}

.flow .inner {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 90%;
  }
}

.flow .title img {
  width: 608px;
  height: 38px;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 195px;
    height: auto;
  }
}

.flow .title:after {
  background-image: url("../img/service/index_line_wh.png");
}


/*
* -------------------------------------------
* リスト
* -------------------------------------------
*/
.flow ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px auto 0;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
    margin: 45px auto 0;
  }
}

.flow ul li {
  position: relative;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    flex: 0 0 17%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 270px;
  }
}

/* 画像が入る */
.flow ul li dl dt {
  position: relative;
}

.flow ul li dl dt img {
  width: 170px;
  height: 170px;
  margin: auto;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 100%;
    height: auto;
  }
}

/* PCの時　矢印 */
.flow dl dt:after {
  content: "";
  background-image: url("../img/service/allow_right.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  height: 26px;
  position: absolute;
  top: 75px;
  right: -25px;
  display: inline-block;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 10%;
    height: 20%;
    top: 45%;
    right: -17%;
    background-size: contain;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    display: none;
  }
}

/* 最後の矢印は消す */
.flow li:nth-child(5) dt:after {
  background-image: none;
}

/* テキスト */
.flow ul li dl dd {
  text-align: center;
  padding-top: 10px;
  line-height: 20px;
}

.flow ul li dl dd h3 {
  font-weight: inherit;
  font-size: inherit;
}

/* SPの時　矢印 */
.flow dl dd:after {
  @media screen and (min-width: 0px) and (max-width: 767px) {
    content: "";
    background-image: url("../img/service/allow_under.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 33px;
    height: 18px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: inline-block;
  }
}

/* 最後の矢印は消す */
.flow li:nth-child(5) dd:after{
  @media screen and (min-width: 0px) and (max-width: 767px) {
    background-image: none;
  }
}


/*
* -------------------------------------------
* メッセージ
* -------------------------------------------
*/
.flow-message {
  background-color: #fff;
  margin: 40px auto 0;
  padding: 30px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 0 auto 0;
    padding: 28px 16px;
  }
}

.flow-message__text {
  font-size: 28px;
  color: #00B695;
  line-height: 1.4;
  text-align: center;

  @media screen and (min-width: 390px) and (max-width: 767px) {
    font-size: 25px;
  }

  @media screen and (min-width: 0px) and (max-width: 389px) {
    font-size: 6.4vw;
  }
}


/*
* -------------------------------------------
* 物件を見るボタン
* -------------------------------------------
*/
.flow_btn_wrapper .btn {
  margin-top: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.4;
}

.flow_btn_wrapper .btn.btn_lp_default img {
  margin-right: 0;
}

.flow_btn_wrapper {
  margin-top: 50px;
  text-align: center;

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    margin-top: 80px;
  }
}