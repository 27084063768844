/*
* 
*
* メインビジュアル
* 
*/


.main_visual {
  position: relative;
  width: 100%;
  height: 534px;
  background-image: url("../img/service/main.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.main_visual:before{
  content: "";
  display: block;
  width: 100%;
  height: 534px;
  background-color: #337a69;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .main_visual {
    height: 558px;
  }

  .main_visual:before {
    height: 558px;
  }
}

/* 物件を見るボタンの余白 */
.main_visual .visual-btn {
  margin-top: 36px;
  text-align: center;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-top: 24px;
  }
}

.visual-btn .btn {
  font-size: 20px;
  border: 4px solid #FFF;
  font-weight: 700;
}

/* キャッチとアプリ画面を包むブロック */
.visual_inner {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 135px;

  @media screen and (min-width: 921px) and (max-width: 1100px) {
    width: 90%;
  }
  @media screen and (min-width: 768px) and (max-width: 920px) {
    width: 92.5%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 80px;
  }
}

.visual_left {
  width: 595px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: auto;
  }
}

.visual_left p {
  display: block;
  font-size: 25px;
  margin: 40px auto 20px;
  width: fit-content;
}

.visual_left img {
  width: 590px;
  height: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 282px;
    margin: auto;
  }
}

.visual_left ul {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: fit-content;
  margin-top: 20px;
}

.visual_left ul li {
  padding: 0 15px 0;   
}

.visual_left dl dt img {
  width: 223px;
  height: 67px;
  margin: auto;
  border: none;
  margin-bottom: 10px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-bottom:0;
  }
}

.visual_left dl dd img {
  width: 94px;
  height: 94px;
  margin: auto;
  border: none;
}


.visual-heading-lv1 {
  text-align: center;
}

/* サブタイトル */
.visual-heading__subttl {
  padding-top: 64px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 42px;
  margin: 0 auto 20px;
  width: fit-content;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-top: 0;
    margin: 0 auto 30px;
  }
}

/* さあ、〜はじめよう */
.visual-heading__ttl {
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 1.4;
  text-align: center;

  @media screen and (min-width: 921px) and (max-width: 1100px) {
    letter-spacing: 0.15em;
    font-size: 4vw;
  }

  @media screen and (min-width: 768px) and (max-width: 920px) {
    letter-spacing: 0.12em;
    font-size: 4.2vw;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 30px;
    letter-spacing: 0;
  }
}

.visual-heading__ttl img {
  display: inline;
  height: 60px;
  width: auto;
  margin-right: 10px;
  vertical-align: baseline;
  margin-bottom: -0.25em;

  @media screen and (min-width: 768px) and (max-width: 920px) {
    height: 1.4em;
    width: auto;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 42px;
    width: auto;
  }
}

/* アプリ画面スライド */
.visual_right {
  position: relative;
  margin-left: 25px;

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    margin-left: 5%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-top: 40px;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-left: 0;
  }
}

.visual_right:before {
  content: "";
  background-image: url("../img/service/phone.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 242px;
  height: 486px;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  display: block;
  z-index: 10;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 197px;
    height: 396px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}

.phone_frame {
  position: relative;
  width: 239px;
  height: 483px;
  overflow: hidden;
  border-radius: 37px;
  z-index: 1;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 193px;
    height: 390px;
    margin: auto;
    border-radius: 30px;
  }
}

.phone_frame img {
  margin: auto;
  height: 483px;
  width: 234px;
  overflow: hidden;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 193px;
    height: 390px;
  }
}


/*
* ============================================
* 掲載・仲介手数料無料
* ============================================
*/
.free-listing-tag {
  width: 100px;
  height: 100px;
  background-color: #FF4F83;
  border: 4px solid #FF95B5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 3px 10px #717171;
  z-index: 1000;
  position: absolute;
  top: 30px;
  left: -70px;

  @media screen and (max-width: 915px) {
    left: -15%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    z-index: 11;
    top: auto;
    bottom: 105px;
    left: 50%;
    transform: translateX(80px);
  }
}

.free-listing {
  font-size: 25px;
  line-height: 1.15;
  font-weight: bold;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.12);
}

.brokerage-fee-tag {
  position: absolute;
  top: 120px;
  left: -70px;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 3px 10px #717171;
  z-index: 999;

  @media screen and (max-width: 915px) {
    left: -15%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    z-index: 10;
    top: auto;
    bottom: 15px;
    left: 50%;
    transform: translateX(80px);
  }
}

.brokerage-fee-tag-inner {
  padding-top: 4px;
}

.brokerage-fee {
  text-align: center;
  color: black;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  line-height: 1;
  padding-bottom: 2px;
}

.brokerage-fee-bottom {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  line-height: 1.3em;
}

.brokerage-fee-half {
  color: #FF3D76;
  font-weight: bold;
  font-size: 16px;
  writing-mode: vertical-rl;
  padding-right: 2px;
}

.brokerage-fee-percent {
  color: #FF3D76;
  font-size: 41px;
  font-weight: bold;
  transform: translate(0, -2px);
  line-height: 1;
  letter-spacing: -0.03em;
}

.brokerage-fee-percent span {
  font-size: 18px;
  margin-left: 2px;
}


/*
* ============================================
* アプリ利用イメージ
* ============================================
*/
.swiper-container {
  height: 484px !important;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 390px !important;
  }
}

.swiper-button-prev {
  display: none;
}

.swiper-button-next {
  display: none;
}