/*
* 
*
* 最後のサービス紹介
* 
*/

.ending__wrapper {
  border-bottom: 4px solid #FFF;
  display: flex;
  gap: 30px;
  margin: 0 auto;
  padding-bottom: 20px;
  width: -moz-fit-content;
  width: fit-content;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ending__ttl {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
}

.ending__subttl {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 35px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-top: 0;
  }
}


.ending {
  padding: 94px 0 90px;
  background-color: #00b695;
  color: #fff;
  text-align: center;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding: 70px 0 60px;
  }
}

.ending .inner dl {
  margin-bottom: 45px;
}

.ending .inner dl dt img {
  width: 754px;
  height: auto;
  margin: 0 auto 35px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
  }
}

.ending dl dd img {
  width: 590px;
  height: auto;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
  }
}

.ending img {
  width: 353px;
  height: auto;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 247px;
  }
}

.ending p {
  font-size: 25px;
  margin: 50px auto 15px;
}

.ending ul {
  width: 475px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px auto 0;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
    margin: 15px auto 0;
  }
}

.ending ul li img {
  width: 223px;
  height: 67px;
  margin: auto;
  border: none;
  margin-bottom: 10px;
}

/* ボタンの余白 */
.ending_btn_wrapper {
  padding-top: 30px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-top: 20px;
  }
}
