/*
* 
*
* 重要なお知らせ
* 
*/

.important {
  background: #FFF4C0;
  padding: 10px 16px 12px;
  margin-top: 56px;
  
  /* PC */
  @media screen and (min-width: 768px) and (max-width: 5000px) {
    margin-top: 85px;
  }
}

.important_head {
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  text-align: center;
  margin: 0;
  color: #85533E;
}

.important_body {
  font-size: 15px;
  line-height: 150%;
  text-align: start;
  margin-top: 5px;

  /* PC */
  @media screen and (min-width: 768px) and (max-width: 5000px) {
    max-width: 1042px;
    margin-left: auto;
    margin-right: auto;
  }
}