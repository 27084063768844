/*
* 
*
* 人物とセリフ
* 
*/

.about {
  width: 100%;
  background-color: #00b695;
  color: #fff;
  position: relative;
}
  
.about .inner .about_tit img {
  width: 713px;
  height: auto;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 271px;
  }
}
  
.about:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-style: solid;
  border-width: 0 50vw 50px 0;
  border-color: transparent transparent #fff transparent;
}
  
.about:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border-style: solid;
  border-width: 0 0 50px 50vw;
  border-color: transparent transparent #fff transparent;
}

.about .inner {
  padding: 155px 0 50px;

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    padding-top: 180px;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding: 270px 0 50px;
  }
}

.about .inner h3 {
  text-align: center;
  line-height: 40px;
}

.about .inner p {
  font-size: 20px;
  text-align: center;
  margin: 45px auto 0;
  line-height: 35px;
}

.about .inner .about_tit p {
  margin: 60px auto 20px;
}

.about .inner img {
  width: 371px;
  height: 51px;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 50%;
    height: auto;
  }
}

.users_wrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1201px) and (max-width: 1360px) {
    padding: 0 4vw;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    padding: 0 7vw;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    display: block;
    margin: auto;
  }
}

/* ブロック一つずつ */
.user_block {
  position: relative;
  flex: 0 0 29.5%;

  @media screen and (min-width: 768px) and (max-width: 1300px) {
    flex: 0 0 33%;
  }

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    padding-bottom: 60px;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-bottom: 42px;
  }
}

.user_block:nth-child(1) {
  z-index: 1;
}
.user_block:nth-child(2) {
  z-index: 3;
}
.user_block:nth-child(3) {
  z-index: 1;
}

/* テキスト */
.user_ttl {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  line-height: 132%;
  margin-bottom: 48px;
  text-wrap: nowrap;

  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: 1.6vw;
  }

  @media screen and (min-width: 901px) and (max-width: 1200px) {
    font-size: 2vw;
  }

  @media screen and (min-width: 768px) and (max-width: 900px) {
    font-size: 2.1vw;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-bottom: 6.2vw;
  }
}

.user_ttl u {
  font-size: 28px;
  display: block;

  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: 1.8vw;
  }

  @media screen and (min-width: 901px) and (max-width: 1200px) {
    font-size: 2.2vw;
  }

  @media screen and (min-width: 768px) and (max-width: 900px) {
    font-size: 2.4vw;
  }
}

/* 人物とふきだしのブロック */
.user_content {
  position: relative;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-top: 6%;
    padding-bottom: 6%;
  }
}

/* 人物画像 */
.user_content img.user_img {
  height: auto;
  width: 100%;
  max-width: 294px;

  @media screen and (min-width: 768px) and (max-width: 1300px) {
    width: 90%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    position: absolute;
    width: 45%;
  }
}

/* SPの時の位置 */
@media screen and (min-width: 0px) and (max-width: 767px) {
  .user_content img.user_img.user_img_1,
  .user_content img.user_img.user_img_3 {
    top: 0;
    left: 0;
    margin-right: auto;
  }

  .user_content img.user_img.user_img_2 {
    top: 0;
    right: 0;
    margin-left: auto;
  }
}

/* PCはアニメーションを消す */
@media screen and (min-width: 768px) and (max-width: 5000px) {
  .user_ttl.animated,
  .user_content img.user_img.animated {
    animation: none;
    opacity: 1;
  }
}


/* ふきだし */
.user_block .user_balloon {
  display: block;
}

/* SP */
@media screen and (min-width: 0px) and (max-width: 767px) {
  .user_block .user_balloon {
    position: relative;
    width: 50%;
    height: auto;
    margin-top: 2%;
  }

  .user_block .user_balloon img {
    width: 100%;
    height: auto;
  }
    
  .user_block .user_balloon.user_balloon_1,
  .user_block .user_balloon.user_balloon_2,
  .user_block .user_balloon.user_balloon_5,
  .user_block .user_balloon.user_balloon_6 {
    right: 0;
    margin-left: auto;
  }

  .user_block .user_balloon.user_balloon_3,
  .user_block .user_balloon.user_balloon_4 {
    left: 0;
    margin-right: auto;
  }
}

/* 768px〜1300px */
@media screen and (min-width: 768px) and (max-width: 1300px) {
  .user_block .user_balloon {
    position: absolute;
    height: 20%;
    display: block;
  }

  .user_block .user_balloon img {
    height: 100%;
    width: auto;
    display: block;
  }

  .user_block .user_balloon.user_balloon_1 {
    top: 30%;
    left: -22%;
  }
  .user_block .user_balloon.user_balloon_2 {
    bottom: 27%;
    left: -22%;
  }
  .user_block .user_balloon.user_balloon_3 {
    top: 33%;
    left: -18%;
  }
  .user_block .user_balloon.user_balloon_4 {
    bottom: 5%;
    right: -16%;
  }
  .user_block .user_balloon.user_balloon_5 {
    top: 48%;
    right: -22%;
  }
  .user_block .user_balloon.user_balloon_6 {
    bottom: 10%;
    right: -22%;
  }
}

/* PC */
@media screen and (min-width: 1301px) and (max-width: 5000px) {
  .user_block .user_balloon {
    position: absolute;
    height: 23%;
    max-height: 70px;
    display: block;
  }

  .user_block .user_balloon img {
    height: 100%;
    width: auto;
    display: block;
  }

  .user_block .user_balloon.user_balloon_1 {
    top: 23%;
    left: -43%;
  }
  .user_block .user_balloon.user_balloon_2 {
    bottom: 25%;
    left: -43%;
  }
  .user_block .user_balloon.user_balloon_3 {
    top: 30%;
    left: -25%;
  }
  .user_block .user_balloon.user_balloon_4 {
    bottom: 5%;
    right: -10%;
  }
  .user_block .user_balloon.user_balloon_5 {
    top: 29%;
    right: -52%;
  }
  .user_block .user_balloon.user_balloon_6 {
    bottom: 19%;
    right: -52%;
  }
}
