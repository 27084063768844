/*
* 
*
* よくあるご質問
* 
*/


.question {
  background-color: #f4f4f4;
  padding-bottom: 90px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-bottom: 75px;
  }
}

.question .inner {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 90%;
  }
}

.question .inner .title img {
  width: 397px;
  height: 38px;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 195px;
    height: auto;
  }
}

.question-list {
  max-width: 700px;
  margin: 70px auto 0;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 80%;
  }
}

.question-list li {
  display: block;
  width: 574px;
  padding: 20px 28px 20px;
  margin: 28px 0 28px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 7px 7px #b8b8b8;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 90%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
    padding: 15px;
  }
}

.question-list li.question-list-item-q {
  position: relative;
  margin-right: 120px;
  font-weight: bold;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    margin-right: 10%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 80%;
    margin: 0 0 0 auto;
  }
}

.question-list li.question-list-item-q h3 {
  font-size: inherit;
  font-weight: inherit;
}


.question-list li.question-list-item-q:before {
  content: "";
  background-image: url("../img/service/col_q.svg");
  background-repeat: no-repeat;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  left: -65px;
  margin: auto;
  display: block;
}

.question-list li.question-list-item-a {
  position: relative;
  margin-left: 120px;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    margin-left: 10%;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 80%;
    margin-left: 0;
  }
}


/*
* ============================================
* ボタン
* ============================================
*/

.question .btn {
  font-size: 28px;
  border: 8px solid #FFF;
  display: block;
  line-height: 1.4;
  margin: 40px auto 0;
  padding: 20px 40px;
  text-align: center;
}

.question .btn img {
  display: inline;
  margin-right: 10px;
  width: 200px;
}

.question ul li.question-list-item-a:after {
  content: "";
  background-image: url("../img/service/col_a.svg");
  background-repeat: no-repeat;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: -65px;
}


.question_btn_wrapper {
  text-align: center;
  padding-top: 6px; /* 34 - 28 */

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    padding-top: 12px; /* 40 - 28 */
  }
}

.question .question_btn_wrapper .btn.btn_lp_default {
  display: inline-flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 4px solid #FFF;
  font-weight: 700;
  margin-top: 0;
  line-height: 1;
}

.question .btn.btn_lp_default img {
  margin-right: 0;
}

.question .question_btn_wrapper .btn.btn_width_logo {
  margin-top: 34px;

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    margin-top: 40px;
  }
}