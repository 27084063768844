/*
* 
*
* こんなサービスです
* 
*/


.intro {
  margin: 90px auto 0;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    position: relative;
    margin-bottom: -20%;
    z-index: -1;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 45px auto 0;
  }
}

.intro .intro_title img {
  width: 827px;
  height: 164px;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 235px;
    height: auto;
  }
}

.intro .intro_content dt img {
  margin-right: 0.25em;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .intro .intro_content dt img {
    width: 239px;
    height: auto;
    margin: auto;
  }
}

.intro_content dl dd {
  text-align: center;
  font-size: 15px;
  margin: 50px auto 0;
  line-height: 26px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 30px auto 0;
    line-height: 23px;
  }
}

.intro_content ul {
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: 47px auto 0;
  justify-content: space-between;
  max-width: 1000px;
  width: 95%;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    justify-content: center;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
    margin: 40px auto 0;
  }
}

/* 左側 相関図 */
.intro_content ul li:nth-child(1) {
  margin-top: 30px;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 48.15%;
  }
}

.intro_content ul li:nth-child(1) img {
  width: 457px;
  height: 358px;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

/* 右側 アプリイメージ */
.intro_content ul li:nth-child(2) {
  display: block;
  width: 316px;
  height: 490px;
  overflow: hidden;
  margin-left: 90px;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    margin-left: 10%;
    width: 33.3%;
    height: auto;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 228px;
    height: 320px;
    margin: 30px auto 0;
  }
}

.intro_content ul li:nth-child(2) img {
  width: 316px;
  height: 641px;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 228px;
    height: auto;
  }
}
