/*
* 
*
* 社長の思い
* 
*/


.president {
  overflow: hidden;
}
 
.president .inner {
  padding-top: 90px;
}
 
.president .president_in {
  display: flex;
  flex-direction: row;
  padding: 60px 60px 0;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0;
  }
}

.president .inner .title {
  padding-top: 0;
}

.president .inner .title img {
  width: 336px;
  height: 38px;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 145px;
    height: auto;
  }
}

.president .pre_left {
  max-width: 575px;
  width: 100%;
  padding-right: 90px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 15px auto 75px;
    padding-right: 0;
  }
}

.president .pre_left dl dt {
  font-size: 25px;
  line-height: 37px;
  font-weight: bold;
  margin-top: 50px;
}

.president .pre_left dl dd {
  margin-top: 30px;
}

.president .pre_right {
  position: relative;
  height: 535px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-top: 50px;
    height: auto;
  }
}

.president .pre_right img {
  width: 341px;
  height: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 80%;
    margin: auto;
  }
}

.president .pre_right dl {
  position: absolute;
  bottom: 140px;
  left: -30px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-left: 10px;
    bottom: 20px;
    left: -10px;
  }
}

.president .pre_right dl dt {
  width: fit-content;
  padding: 4px 8px 5px;
  background-color: #00b695;
  font-size: 12px;
  line-height: 1em;
  color: #fff;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 15px;
  }
}

.president .pre_right dl dd {
  padding: 5px 10px 7px;
  background-color: #00b695;
  font-size: 20px;
  color: #fff;
  line-height: 1em;
  margin-top: 5px;
}