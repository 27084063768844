/*
* 
*
* こんなトラブルも安心
* 
*/


.trouble {
  overflow: hidden;
  padding-bottom: 90px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-bottom: 60px;
  }
}

.trouble .inner {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 90%;
  }
}

.trouble .title img {
  width: 687px;
  height: auto;   
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 261px;
  }
}

/* ケース1 タイトル */
.trouble dl.case01 dt img {
  height: 70px;
  width: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 50px;
    margin-left: 0;
    margin-right: auto;
  }
}

/* ケース2 タイトル */
.trouble dl.case02 dt img {
  height: 70px;
  width: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    height: 50px;
  }
}

.trouble dl dd {
  margin-top: 30px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-top: 15px;
    text-align: left;
  }
}

.trouble dl.case01 {
  padding: 65px 150px 0 0;
  align-self: center;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-top: 0;
    padding-right: 0;
  }
}

.trouble dl.case02 {
  padding: 85px 0 0 100px;
  align-self: center;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-top: 0;
    padding-left: 0;
  }
}

.trouble_left {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 70px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: auto;
    max-width: 350px;
    width: auto;
    display: block;
  }
}

.trouble_left:after {
  content: "";
  display: block;
  width: 3000px;
  height: 180px;
  background-color: #f4f4f4;
  position: absolute;
  bottom: 0px;
  right: -150px;
  z-index: -1;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    bottom: -15px;
    left: -500px;
    right: 0;
    margin: auto;
    height: 90px;
  }
}

.trouble_left img {
  width: 350px;
  height: 300px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 244px;
    height: auto;
    margin: 0 0 0 auto;
  }
}

.trouble_right {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 30px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: auto;
    max-width: 350px;
    width: auto;
    display: block;
  }
}

.trouble_right:after {
  content: "";
  display: block;
  width: 3000px;
  height: 180px;
  background-color: #f4f4f4;
  position: absolute;
  bottom: 0;
  left: -5px;
  z-index: -1;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    bottom: -15px;
    left: -500px;
    height: 110px;
  }
}

.trouble_right img {
  width: 313px;
  height: 319px;
}

.trouble_right img:nth-child(1) {
  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 232px;
    height: auto;
    margin: 0 auto 0 0;
  }
}

.trouble p {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin: 50px auto 0;
}

.trouble .inner img.trouble-foot {
  width: 640px;
  height: 68px;
  margin: 50px auto 0;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 286px;
    height: auto;
  }
}