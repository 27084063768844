@charset "UTF-8";


/*
* ============================================
* 共通要素
* ============================================
*/
.inner {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.lp-container {
  font-size: 15px;
  line-height: 26px;
}

.lp-container .animated {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .inner {
    width: 90%;
    margin: auto;
    max-width: 90%;
  }
}


/*
* ============================================
* 全ブロック共通　物件を見るボタン base.cssの.btnを使う
* ============================================
*/
.btn.btn_lp_default {
  display: inline-flex;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 4px solid #FFF;
  font-weight: 700;
}
  
.btn.btn_lp_default img.btn__arrow {
  height: 20px;
  width: auto;
  margin-left: 10px;
  display: block;
}

.btn.btn_lp_default.btn_border0 {
  border-width: 0;
}

/* PCで大きく表示するボタン */
@media screen and (min-width: 768px) and (max-width: 5000px) {
  .btn.btn_lp_default.btn_large {
    font-size: 24px;
    padding: 16px 0;
    width: 320px;
  }

  .btn.btn_lp_default img.btn__arrow {
    margin-left: 12px;
  }
}


/*
* ============================================
* 全ブロック共通　ロゴありボタン　base.cssの.btnを使う
* ============================================
*/
@media screen and (min-width: 0px) and (max-width: 767px) {
  .btn.btn_lp_with_logo img {
    margin-right: 0;
  }
}

@media screen and (min-width: 390px) and (max-width: 767px) {
  .btn.btn_lp_with_logo {
    font-size: 26px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn.btn_lp_with_logo img {
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 0px) and (max-width: 389px) {
  .btn.btn_lp_with_logo {
    font-size: 6.7vw;
    padding-left: 5.1vw;
    padding-right: 6.1vw;
  }
  .btn.btn_lp_with_logo img {
    margin-bottom: 1.5vw;
  }
}


/*
* ============================================
* アプリダウンロード
* ============================================
*/
@media screen and (min-width: 0px) and (max-width: 767px) {
  .sp_header {
    position: relative;
    width: 100%;
    height: 55px;
    background-color: #fff;
    z-index: 1;
  }

  img.top_btn{
    width: 108px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 11px;
    margin: auto;
    display: block;
  }
}


/*
* ============================================
* ふきだしの大見出し
* ============================================
*/
.title {
  position: relative;
  display: block;
  margin: auto;
  padding: 90px 0 25px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding: 55px 0 25px;
  }
}

.title:after {
  content: "";
  background-image: url("../img/service/index_line.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  max-width: 1000px;
  height: 14px;
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    content: "";
    background-image: url("../img/service/index_line.png");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    max-width: 1000px;
    height: 14px;
  }
}

.title__text {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 36px;
  }
}

.title__text img {
  display: inline;
  height: 48px;
  margin-right: 10px;
}