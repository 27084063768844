/*
* 
*
* おすすめな理由
* 
*/


.recommend {
  width: 100%;
  background-color: #00b695;
  padding: 0 0 120px;

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    padding-bottom: 80px;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding: 0 0 80px;
  }
}

.recommend .inner {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 90%;
  }
}

.recommend .inner .recommend-content {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}


/*
* -------------------------------------------
* ふきだしタイトル
* -------------------------------------------
*/
.recommend .title {
  margin-top: 0;
  color: #fff;
}

.recommend .title img {
  width: 396px;
  height: 36px;
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 195px;
    height: auto;
    margin: 10px auto 0;
  }
}

.recommend .title h3:before {
  content: "";
  background-image: url("../img/service/fufu_wh.png");
}

.recommend .title:after {
  content: "";
  background-image: url("../img/service/index_line_wh.png");
}


/*
* -------------------------------------------
* コンテンツ　ブロック
* -------------------------------------------
*/
.recommend-content {
  width: 100%;
  background-color: #fff;
  border-radius: 30px;
  margin: 42px auto 84px;
  padding: 60px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 42px auto 50px;
    padding: 40px 20px 40px;
  }
}


/*
* -------------------------------------------
* コンテンツブロックの基本スタイル
* -------------------------------------------
*/
.recommend-content p {
  font-size: 15px;
  text-align: center;
  line-height: 26px;
  margin: 35px 0 0 0;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    line-height: 23px;
    margin: 30px 0 0 0;
  }
}

/* .recommend-content img {
  margin: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    position: relative;
    margin: auto;
    width: 100%;
    height: auto;
    z-index: 1;
  }
} */


/*
* -------------------------------------------
* 数字
* -------------------------------------------
*/
.recommend-number {
  text-align: center;
  margin-bottom: 0;
}
.recommend-number img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 43px;
    height: 43px;
  }
}


/*
* -------------------------------------------
* 黄色い線の見出し
* -------------------------------------------
*/
.recommend-heading {
  margin: 15px 0 25px;
  text-align: center;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: 10px 0 20px;
  }
}

.recommend-heading-text {
  display: inline;
  font-size: 25px;
  font-weight: bold;
  background: linear-gradient(transparent 50%, #FFFF33 0%);
  line-height: 1.6;
  text-shadow: 0 0 1px #000;

  @media screen and (min-width: 768px) and (max-width: 820px) {
    font-size: 4.7vw;
  }

  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 4.7vw;
  }
}

/* 1つ目ブロックだけ1100pxで改行 */
.recommend-heading1-br {
  display: none;

  @media screen and (min-width: 0px) and (max-width: 1100px) {
    display: block;
  }
}

/* 3つ目ブロックの1.5% */
.recommend-heading3-num {
  padding-left: 0.45em;
  padding-right: 0.3em;
  font-family: 'Noto Sans JP';
  transform: scale(1.2, 1.1);
  display: inline-block;
}

/*
* -------------------------------------------
* イメージ画像
* -------------------------------------------
*/
.recommend-image {
  max-width: 100%;
  height: auto;
}


/*
* -------------------------------------------
* ポイント
* -------------------------------------------
*/
.recommend-point {
  position: relative;
  width: 100%;
  max-width: 870px;
  border: 3px solid #00b695;
  display: flex;
  margin-top: 45px;
  /* padding: 0 0 50px; */

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin-top: 40px;
    padding: 30px 20px 0;
  }
}

/* PC:水平、SP:垂直 */
.recommend-point.recommend-point--h {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
  }
}

/* PC:垂直、SP:垂直 */
.recommend-point.recommend-point--v {
  flex-direction: column;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    flex-direction: column;
  }
}

.recommend-point h5 {
  font-size: 30px;
  color: #00b695;
  padding: 20px;
  background-color: #fff;
  position: absolute;
  top: -35px;
  left: 30px;
}

/* POINT 見出し */
.recommend-point__title {
  display: block;
  width: 142px !important;
  height: 62px !important;
  color: #00b695;
  background-color: #fff;
  position: absolute;
  top: -31px;
  left: 30px;
  padding: 20px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 113px !important;
    height: 29px !important;
    top: -15px;
    left: 20px;
    padding: 3px 7px 7px 15px;
  }
}

/* 説明テキスト */
.recommend-point dl {
  width: 60%;
  padding: 35px 60px 30px 40px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
}

.recommend-point.recommend-point--v dl {
  width: 100%;
  padding-right: 40px;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    padding-right: 0;
  }
}

/* 説明テキスト タイトル */
.recommend-point dl dt {
  font-size: 20px;
  text-align: center;
  font-weight: bold;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 15px;
  }
}

/* 説明テキスト 詳細 */
.recommend-point dl dd {
  margin-top: 20px;
}

/* 1つ目ブロック　ポイント画像 */
img.recommend-point-image1 {
  width: 276px;
  height: auto;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 202px;
    margin-left: auto;
    margin-right: -20px;
  }
}

/* 2つ目ブロック　ポイント画像 */
img.recommend-point-image2 {
  width: 270px;
  height: 201px;
  margin-right: 5%;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    margin: auto;
  }
}

/* 3つ目ブロック　ポイント画像 */
img.recommend-point-image3 {
  height: auto;
  max-width: 712px;
  margin: 0 auto 50px;
  width: 90%;

  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
  }
}


/*
* -------------------------------------------
* 物件を見るボタン
* -------------------------------------------
*/
.recommend_btn_wrapper {
  margin-top: 50px;
  text-align: center;

  @media screen and (min-width: 768px) and (max-width: 5000px) {
    margin-top: 80px;
  }
}
